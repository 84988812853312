<template>
  <div class="checkbox">
    <div
      v-if="label || $slots.label"
      class="checkbox__label"
      :class="{ small: smallLabel }"
    >
      <slot name="label">
        <template v-if="label">{{ label }}</template>
      </slot>
    </div>
    <div class="checkbox__input">
      <input type="checkbox" @change="onchange" />
      <div class="checkbox__overlay" :class="{ checked: model }">
        <OsIcon v-if="model" name="Tick" class="text-white" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
type CheckBoxEvent = Omit<Event, 'target'> & { target: HTMLInputElement }

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    smallLabel: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const model = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    const onchange = (event: CheckBoxEvent) => {
      model.value = event.target.checked
    }

    return {
      model,
      onchange
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  .checkbox__input {
    display: inline-block;
    width: 24px;
    min-height: 24px;
    height: 24px;
    position: relative;
    vertical-align: middle;
  }

  .checkbox__label {
    font-family: $mb-font-text-bold;
    font-size: rem(16);
    display: inline-block;
    padding-right: 5px;
    width: calc(100% - 30px);
    vertical-align: top;
    color: $black--light;

    &.small {
      font-size: rem(14);
    }
  }

  input {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .checkbox__overlay {
    border: 1px solid #919191;
    box-shadow: inset 0 0 3px 1px rgba(33, 33, 33, 0.2);
    border-radius: 2px;
    width: 100%;
    height: 100%;

    svg {
      margin: auto;
      display: block;
      height: 100%;
    }

    &.checked {
      background: $blue;
      border: none;
    }
  }
}
</style>
